import { Token, ChainId } from '@pancakeswap/sdk'
import { bscTokens } from '@pancakeswap/tokens'
import { CAKE_BNB_LP_MAINNET } from './lp'
import { Ifo } from './types'

export const cakeBnbLpToken = new Token(ChainId.BSC, CAKE_BNB_LP_MAINNET, 18, 'CAKE-BNB LP')

const ifos: Ifo[] = [
  // {
  //   id: 'shdw',
  //   address: '0x5dAB3D3532c022f75a63f30700318522F46fE50F',
  //   isActive: false,
  //   name: 'SHDW',
  //   poolBasic: {
  //     saleAmount: '2,500,000 SHDW',
  //     raiseAmount: '$250,000',
  //     cakeToBurn: '$0',
  //     distributionRatio: 0,
  //   },
  //   poolUnlimited: {
  //     saleAmount: '10,000,000 SHDW',
  //     raiseAmount: '$1,000,000',
  //     cakeToBurn: '$0',
  //     distributionRatio: 1,
  //   },
  //   currency: bscTokens.wbnb,
  //   token: bscTokens.cake,
  //   releaseBlockNumber: 920549,
  //   campaignId: '511400000',
  //   articleUrl: 'shadowswap.xyz',
  //   tokenOfferingPrice: 0.1,
  //   version: 3.2,
  //   twitterUrl: 'https://twitter.com/Shadowswap_xyz',
  //   description:
  //     'ShadowSwap is a one-stop decentralized trading protocol which utilizes the security, scalability, and decentralization of the Core blockchain, creating an open and safe marketplace for traders, liquidity providers, and developers.',
  //   vestingTitle: 'Earn $WOM by staking stablecoins; Lock $WOM to maximize stablecoin yields',
  // },
  // {
  //   id: 'tifot',
  //   address: '0x9A409696DFf0D69e52Ce388a8A39f5443c081996',
  //   isActive: false,
  //   name: 'tifot',
  //   poolBasic: {
  //     saleAmount: '2,500,000 tifot',
  //     raiseAmount: '$250,000',
  //     cakeToBurn: '$0',
  //     distributionRatio: 0,
  //   },
  //   poolUnlimited: {
  //     saleAmount: '10,000,000 tifot',
  //     raiseAmount: '$1,000,000',
  //     cakeToBurn: '$0',
  //     distributionRatio: 1,
  //   },
  //   currency: bscTokens.wbnb,
  //   token: bscTokens.tifot,
  //   releaseBlockNumber: 920549,
  //   campaignId: '511400000',
  //   articleUrl: 'shadowswap.xyz',
  //   tokenOfferingPrice: 0.1,
  //   version: 3.2,
  //   twitterUrl: 'https://twitter.com/Shadowswap_xyz',
  //   description:
  //     'ShadowSwap is a one-stop decentralized trading protocol which utilizes the security, scalability, and decentralization of the Core blockchain, creating an open and safe marketplace for traders, liquidity providers, and developers.',
  //   vestingTitle: 'Earn $WOM by staking stablecoins; Lock $WOM to maximize stablecoin yields',
  // },
  // {
  //   id: 'cpt',
  //   address: '0xaA9A97E4975974cb3a29BB45BD89d978790CC901',
  //   isActive: false,
  //   name: 'CPT',
  //   poolBasic: {
  //     saleAmount: '2,500,000 SHDW',
  //     raiseAmount: '$250,000',
  //     cakeToBurn: '$0',
  //     distributionRatio: 0,
  //   },
  //   poolUnlimited: {
  //     saleAmount: '8,500,000 CPT',
  //     raiseAmount: '$150,000',
  //     cakeToBurn: '$0',
  //     distributionRatio: 1,
  //   },
  //   currency: bscTokens.cake,
  //   token: bscTokens.cpt,
  //   releaseBlockNumber: 920549,
  //   campaignId: '511400002',
  //   articleUrl: 'https://crestprotocol.co',
  //   tokenOfferingPrice: 0.017,
  //   version: 3.2,
  //   twitterUrl: 'https://twitter.com/crestprotocol_',
  //   description:
  //     'Crest Protocol is a decentralized naming service built on the CoreDAO blockchain. It aims to provide a seamless and user-friendly experience for discovering and accessing decentralized services while enabling increased security through decentralized ownership and control of domain names. Crest Protocol also empowers communities and developers to create and manage their own domain names, allowing the decentralized web to reach its full potential.',
  //   vestingTitle: 'Earn $WOM by staking stablecoins; Lock $WOM to maximize stablecoin yields',
  // },

  // {
  //   id: 'yz',
  //   address: '0x7edf0aeb040dBCd96A7f85a9F896d588a344988E',
  //   isActive: false,
  //   name: 'YIELDZ',
  //   poolBasic: {
  //     saleAmount: '2,500,000 SHDW',
  //     raiseAmount: '$250,000',
  //     cakeToBurn: '$0',
  //     distributionRatio: 0,
  //   },
  //   poolUnlimited: {
  //     saleAmount: '4,500,000 YZ',
  //     raiseAmount: '$499,500',
  //     cakeToBurn: '$0',
  //     distributionRatio: 1,
  //   },
  //   currency: bscTokens.cake,
  //   token: bscTokens.yz,
  //   releaseBlockNumber: 920549,
  //   campaignId: '511400003',
  //   articleUrl: 'https://yieldzprotocol.com/',
  //   tokenOfferingPrice: 0.111,
  //   version: 3.2,
  //   twitterUrl: 'https://twitter.com/Yieldz_protocol',
  //   description:
  //     'Yieldz is a “real yield” reward based optimized economic system that uses the fees generated by the protocol in the most optimal way.Paying out the generated protocol fess to stakers through an ecosystem booster token(bYZ), which prevents inflation and distribute the rewards through a multi rewarding SHDW + CORE pool in connection with incorporate features, like governance system that allows bYZ holders to decide on important questions of the platform.',
  //   vestingTitle: 'Earn $WOM by staking stablecoins; Lock $WOM to maximize stablecoin yields',
  // },

  {
    id: 'shdw',
    address: '0x92585eE9D39105eF36Fbb239DED97793397aCDF8',
    isActive: false,
    name: 'TIFOT',
    poolBasic: {
      saleAmount: '100 TIFOT',
      raiseAmount: '$210,000',
      cakeToBurn: '$0',
      distributionRatio: 0.1,
    },
    poolUnlimited: {
      saleAmount: '100 TIFOT',
      raiseAmount: '$1,890,000',
      cakeToBurn: '$0',
      distributionRatio: 1,
    },
    currency: bscTokens.cake,
    token: bscTokens.tifot,
    releaseBlockNumber: 20685666,
    campaignId: '511400000',
    articleUrl:
      'https://pancakeswap.finance/voting/proposal/bafkreieqv7mbzmumyftstt6l32x6okfzq4syrea7k5zbqgohhcekcvbduu',
    tokenOfferingPrice: 0.075,
    version: 3.2,
    twitterUrl: 'https://twitter.com/WombatExchange',
    description: 'Wombat Exchange is a next generation multi-chain stableswap native to BNB Chain.',
    vestingTitle: 'Testing of ShadowPad',
  },
  {
    id: 'crust',
    address: '0x55f0CAF54f32ddf3EE0c11e5C3F1e8338b4E123c',
    isActive: false,
    name: 'CRUST',
    poolBasic: {
      saleAmount: '100 CRUST',
      raiseAmount: '$210,000',
      cakeToBurn: '$0',
      distributionRatio: 0.1,
    },
    poolUnlimited: {
      saleAmount: '150,000 CRUST',
      raiseAmount: '$230,400',
      cakeToBurn: '$0',
      distributionRatio: 1,
    },
    currency: bscTokens.cake,
    token: bscTokens.crust,
    releaseBlockNumber: 20685666,
    campaignId: '511400000',
    articleUrl: 'https://www.crustexchange.io/',
    tokenOfferingPrice: 1.51,
    version: 3.2,
    twitterUrl: 'https://twitter.com/CrustExchange',
    description:
      'Crust Exchange is a Derivatives Decentralized Exchange with Advanced Perpetual Trading Capabilities in $CORE with up to 50X Leverage',
    vestingTitle: 'Crust Exchange is a Derivatives Decentralized Exchange',
  },

  {
    id: 'ars',
    address: '0xd63C891B6475578Ef6Cf5f8B3DA3851E9e5377cE',
    isActive: true,
    name: 'ARS',
    poolBasic: {
      saleAmount: '100 CRUST',
      raiseAmount: '$210,000',
      cakeToBurn: '$0',
      distributionRatio: 0.1,
    },
    poolUnlimited: {
      saleAmount: '25,000,000 ARS',
      raiseAmount: '$250,000',
      cakeToBurn: '$0',
      distributionRatio: 1,
    },
    currency: bscTokens.wbnb,
    token: bscTokens.ars,
    releaseBlockNumber: 20685666,
    campaignId: '511400000',
    articleUrl: 'https://www.aquarius.loan/',
    tokenOfferingPrice: 0.01,
    version: 3.2,
    twitterUrl: 'https://twitter.com/AquariusLoan',
    description:
      'A one stop shop DeFi platform for lenders and borrowers using borderless stablecoins for fast and affordable transactions on CORE DAO',
    vestingTitle: 'The First Money Market on CORE DAO',
  },
]

export default ifos
